import React, { useEffect } from "react";
import { Grid, Typography, Link, Modal } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { graphql, navigate } from "gatsby";
import { ParticipantKey, Paths } from "src/utilities/constants";
import { StyledButton } from "src/components/buttons";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { CustomLayout } from "src/components/layout";
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";
import { HomePageQueryQuery } from "types/graphql-types";
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { loginAnonymous, resetUser, setScheduleId, setUserToken } from "src/state/features/user/userSlice";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from '@reach/router';
import Mustache from 'mustache';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    input: {
      margin: "2em 0",
    },
    continueContainer: {
      width: "100%",
      margin: "0 auto",
      textAlign: "center"
    },
    linkColor: {
      color: "#c8602a",
    },
    labelWrapper: {
      alignItems: "flex-start",
      margin: 0,
      padding: 0,
      border: "none",
    },
    label: {
      paddingTop: "4px",
      fontSize: "inherit",
      textAlign: "left",
      color: "#c8602a"
    },
    button: {
      minWidth: "13.75rem",
      margin: "1.875rem auto 1.25rem auto",
      textTransform: "none",
    },
    link: {
      display: "inline-block",
      marginBottom: "2.5rem",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      maxWidth: "20rem",
      margin: "auto",
      padding: "2em",
      backgroundColor: "#fff",
      borderRadius: "1rem",
      boxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
      transform: "translate(-50%, -50%)",
      width: "80%"
    },
    modalHeading: {
      fontFamily: "'Roboto Condensed'",
      fontSize: "1.1875rem",
      fontWeight: 400,
      textTransform: "capitalize",
      color: "white",
      backgroundColor: "#D42C22",
      padding: '8px 24px',
    },
    headerTitle: {
      display: 'block',
      color: "inherit",
      fontFamily: "inherit",
      fontSize: "12px",
      fontWeight: 300,
    },
    headerSubtitle: {
      display: 'block',
      color: "inherit",
      fontFamily: "inherit",
      fontSize: "18px",
      fontWeight: 900,
      textTransform: "uppercase",
    },
    contentTitle: {
      display: 'block',
      color: "inherit",
      fontFamily: "inherit",
      fontSize: "18px",
      fontWeight: 900,
      textTransform: "uppercase",
    },
    contentBody: {
      color: "inherit",
      fontFamily: "inherit",
      fontSize: "12px",
      fontWeight: 400,
    },
    modalImage: {
      margin: "auto",
      height: 104,
      width: 57,
      marginTop: 10,
      marginBottom: 20,
    },
    modalButton: {
      color: "white",
      fontFamily: "inherit",
      fontSize: "14px",
      fontWeight: 700,
      backgroundColor: "#D42C22",
      borderRadius: '10em',
      textTransform: 'none',
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 14,
      marginBottom: 8,
    },
    modalCloseButton: {
      color: "#D42C22",
      fontFamily: "inherit",
      fontSize: "14px",
      fontWeight: 700,
      backgroundColor: "white",
      borderRadius: '10em',
      textTransform: 'none',
      textAlign: 'center',
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

    },
    modalLink: {
      padding: "0.2rem",
      margin: "0.1rem",
      backgroundColor: "#c8602a",
      color: "#fff",
      textDecoration: "none",
      width: "60%",
      height: "50%",

    },
    modalInput: {
      padding: "0.5rem",
      height: "36px",
      borderRadius: "18px",
      color: "#9E9E9E",
      fontFamily: "inherit",
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "italic",
      borderColor: "#D42C22",
      outline: 'none',
    },
    errorCode: {
      color: "red",
      marginTop: "10px"
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      padding: '4px',
    },
  });

const useStyles = makeStyles(styles);

const Dialog = withStyles((theme: Theme) => ({
  paper: {
    height: 481,
    width: 291,
    borderRadius: "1rem",
  },
}))(MuiDialog);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.modalHeading} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ color: "white", fontSize: '18px' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface IProps {
  data: HomePageQueryQuery
}

const Index = ({ data }: IProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-device-width: 1280px)'
  });
  const dispatch = useDispatch();
  const homePageData = data.allContentfulHomePage.edges[0].node;
  const sharedContent = data.contentfulSharedContent;
  const location = useLocation();
  const [modalOpen, setModalOpen] = React.useState(isDesktop);
  const [emailTo, setEmail] = React.useState("");

  const classes = useStyles();

  useEffect(() => {
    async function getToken() {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get("code");
      const scheduleId = searchParams.get("schedule");
      // -- user can from a token. grab the available packages for this user. 
      if (token != null) {
        await dispatch(setUserToken(token));
      } else if (scheduleId != null) {
        // -- Get details based on scheduleID
        await dispatch(setScheduleId(scheduleId));
        // -- Check if there is a token saved
        // const tokenId = getItem(scheduleId);
        // if (tokenId !== "") {
        //   await dispatch(setUserToken(tokenId));
        // }
      }
      else {
        dispatch(resetUser());
      }
    }
    getToken();
  }, []);

  const headerProps: IHeaderProps = {
    type: headerTypes.logo
  }

  function onContinueAsGuestClick() {
    dispatch(loginAnonymous());
    navigate(Paths.SELECTION);
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
  }

  function getEmailLink() {

    const encoded = encodeURIComponent(location.href);
    const mustacheHash = {
      link_url: encoded
    };

    const template = Mustache.render(homePageData.warningModalEmailContentText as string, mustacheHash);

    const mailToLink = `mailto:${emailTo}?subject=${homePageData.warningModalEmailHeader}&body=${template}%0D`;

    return mailToLink;
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  const modal = (
    <Dialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={modalOpen}>
      <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
        <span className={classes.headerTitle}>{homePageData.warningModalHeaderTitle}</span>
        <span className={classes.headerSubtitle}>{homePageData.warningModalHeaderSubtitle}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <img src={homePageData?.icon?.file?.url!} alt="Mobile Icon" className={classes.modalImage} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <span className={classes.contentTitle}>{homePageData.warningModalContentTitle}</span>
              <p className={classes.contentBody}>{homePageData.warningModalContentBody}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputContainer}>
              <input
                type="email"
                onChange={handleEmailChange}
                autoComplete="on"
                placeholder="Your email address"
                className={classes.modalInput}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Link href={getEmailLink()} target="_blank" rel="noopener" >
              <Button
                type="button"
                variant="contained"
                color="primary"
                classes={{ root: classes.modalButton }}
                onClick={() => { }}
              >
                {homePageData?.sendModalButtonLabel}
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.modalCloseButton}>
              <span style={{ cursor: 'pointer' }} onClick={handleModalClose}>{homePageData?.confirmModalButtonLabel}</span>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )

  return (
    <CustomLayout
      footerProps={{}}
      headerProps={headerProps}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ContentfulRichText {...homePageData?.content as ContentfulRichTextType} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            label={homePageData.continueAsGuestButtonLabel as string}
            onClickHandler={onContinueAsGuestClick}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      {modal}
    </CustomLayout>
  );
}

export const query = graphql`
query HomePageQuery {
  contentfulSharedContent {
    logo {
      file {
        url
        contentType
      }
    }
  }
  allContentfulHomePage {
    edges {
      node {
        id
        content {
          raw
        }
        loginText
        loginButtonLabel
        createAccountText
        createAccountButtonLabel
        continueAsGuestText
        continueAsGuestButtonLabel
        warningModalHeaderTitle
        warningModalHeaderSubtitle
        icon {
          file {
            url
            contentType
          }
        }
        warningModalContentTitle
        warningModalContentBody
        warningModalEmailHeader
        warningModalEmailContent {
          raw
        }
        warningModalEmailContentText
        confirmModalButtonLabel
        sendModalButtonLabel
      }
    }
  }
}
`

export default Index;